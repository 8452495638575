<template>
  <div id="leftbar">
    <div class="button">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </div>
    <ul class="icons">
      <li>
        <font-awesome-icon :icon="['fas', 'cash-register']" />
      </li>
      <li>
        <font-awesome-icon :icon="['far', 'calendar']" />
      </li>
      <li>
        <font-awesome-icon :icon="['fas', 'users']" />
      </li>
      <li>
        <!-- <font-awesome-icon :icon="['far', 'chart-bar']" /> -->
        <font-awesome-icon :icon="['fas', 'signal']" />
      </li>
      <li>
        <font-awesome-icon :icon="['fas', 'chart-pie']" />
      </li>
      <li>
        <font-awesome-icon :icon="['far', 'lightbulb']" />
      </li>
      <li>
        <font-awesome-icon :icon="['fas', 'exchange-alt']" />
      </li>
      <li>
        <font-awesome-icon :icon="['fas', 'store']" />
      </li>
    </ul>
  </div>
</template>

<style scoped>
#leftbar
{
  text-align: center;
}
.button
{
  margin: 20px 0;
  padding: 4px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s;
}
.button:hover
{
  opacity: 0.8;
}
.icons
{
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0px;
}
.icons li
{
  padding: 12px 4px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s;
}
.icons li:hover
{
  opacity: 0.8;
}
</style>

<script>
export default {
  props: {
  },
  computed:
  {

  },
  mounted()
  {

  },
}
</script>