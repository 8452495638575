<template>
  <nav id="header">
    <div class="datetime_cont">
      <span class="date">{{ getDate }}</span>
      <br>
      <span class="time">{{ getTime }}</span>
    </div>
    <div class="credito">
      <span class="red">Credito SMS: {{ getCredito }}</span>
      <font-awesome-icon :icon="['fas', 'shopping-cart']" />
    </div>
    <div class="store_info_cont green">
      <span class="licenza">Licenza valida fino al: {{ licenza }}</span>
      <br>
      <span class="indirizzo">{{ indirizzo }}</span>
    </div>
    <div class="icons icons_1">
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'cash-register']" />
      </div>
      <div class="icon">
        <font-awesome-icon :icon="['far', 'calendar']" />
      </div>
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'print']" />
      </div>
    </div>
    <div class="icons icons_2">
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'clipboard-list']" />
      </div>
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'signal']" />
      </div>
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'birthday-cake']" />
      </div>
      <div class="icon blue">
        <font-awesome-icon :icon="['fas', 'ticket-alt']" />
      </div>
      <div class="icon green">
        <font-awesome-icon :icon="['fas', 'flag']" />
      </div>
    </div>
    <div class="logo_content">
      <img class="hover_opacity" src="logo.png">
    </div>
  </nav>
</template>

<style scoped>
nav
{
  font-size: 12px;
  display: flex;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  flex-wrap: wrap;
}
.datetime_cont
{
  text-align: center;
}
.time
{
  font-size: 14px;
  font-weight: 800;
}
.credito span
{
  color: #E91E63;
  margin-right: 8px;
}
.green
{
  color: seagreen;
}
.indirizzo
{
  font-weight: 600;
}
.icons
{
  display: flex;
  font-size: 1rem;
  align-items: center;
}
.icon
{
  padding: 4px 12px;
  cursor: pointer;
  transition: 0.2s all;
  opacity: 1;
}
.icon:hover
{
  opacity: 0.8;
}
.blue
{
  color: #2196f3;
}
.logo_content
{
  height: 100%;
}
.logo_content img
{
  max-height: 100%;
}
</style>

<script>
export default {
  props: {
    licenza: String,
    indirizzo: String
  },
  computed:
  {
    getDate()
    {
      return "Gio 1 Febbraio 2024";
    },
    getTime()
    {
      return "14.33";
    },
    getCredito()
    {
      return "-75";
    },
  },
  mounted()
  {

  },
}
</script>