<template>
  <LeftBar></LeftBar>
  <div id="header_calendar_cont">
    <Header
    :indirizzo="'Roma, Via Alessandria 215'"
    :licenza="'30/01/2025'"
    ></Header>
    <Calendar></Calendar>
  </div>
</template>

<script>
import Header from './components/partials/Header.vue'
import LeftBar from './components/partials/LeftBar.vue'
import Calendar from './components/Calendar.vue'

export default {
  name: 'App',
  components: {
    Header,
    LeftBar,
    Calendar
  }
}
</script>

<style>
body
{
  margin: 0;
}
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html, body, #app
{
  height: 100%;
}
#app
{
  display: grid;
  grid-template-columns: 80px 1fr;
}
#header_calendar_cont
{
  display: grid;
  grid-template-rows: 50px calc(100vh - 50px);
}
</style>
