<template>
  <div id="calendar">
    <!-- <div class="calendar_head">
      calendar head
    </div> -->
    <div class="calendar_body">
      <div class="plan">
        <div class="orario_empty_cell employ_name"></div>
        <div v-for="orario in orari" :key="orario" class="orario_cell" :id="orario">
          {{ orario }}
        </div>
      </div>
      <div class="employees">
        <div v-for="employ in employees" :key="employ.id" class="employ_column" :class="(!employ.presente)?'assente':''">
          <div class="employ_name">
            {{ employ.name }}
          </div>
          <div v-if="!employ.presente">
            <div class="label_assente">ASSENTE</div>
          </div>
          <div v-else class="appuntamenti">
            <div v-for="orario in orari" :key="orario" class="appuntamento_cell" :id="orario">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#calendar
{
  background: #ecebec;
  font-size: 12px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}
.calendar_head
{
  height: 50px;
  padding: 4px;
  display: flex;
  align-items: center;
}
.calendar_body
{
  display: flex;
}
.plan
{
  width: 80px;
  border-right: 1px solid #2c3e50;
  /* position: sticky;
  left: 0; */
}
.orario_cell
{
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #9E9E9E;
  font-weight: 700;
  width: 72px;
  padding-right: 8px;
}
.employees
{
  border-bottom: solid 1px;
  display: flex;
}
.employ_column
{
  background: #f2f5fa;
  width: 120px;
  border-left: 1px solid #9E9E9E;
}
.employ_column.assente
{
  background: repeating-linear-gradient(  45deg,  white,  white 20px,  #ecebec 20px,  #ecebec 40px);
}
.employ_name
{
  text-align: center;
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #9E9E9E;
  position: sticky;
  top: 0;
  background: #ecebec;
}
.orario_empty_cell.employ_name
{
  position: relative;
}
.label_assente
{
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  padding: 4px;
  background: #E91E63;
}
.employ_cell
{
  border-left: 1px solid;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 10px;
}
.appuntamento_cell
{
  height: 50px;
  border-bottom: 1px solid #9E9E9E;
}
.appuntamento_cell:last-of-type
{
  border-bottom: none;
}
</style>

<script>
export default {
  props: {
  },
  data(){
    return {
      'employees': [
        {'id':1, 'name':'Gianni', 'presente': true, 'appuntamenti': [
          {'orario':'8.30', 'orario_fine':'9.30', 'tipologia':'taglio semplice'},
          {'orario':'10.00', 'orario_fine':'11.00', 'tipologia':'taglio semplice'},
          {'orario':'11.00', 'orario_fine':'11.30', 'tipologia':'piega'},
        ]},
        {'id':2, 'name':'Spa', 'presente': true, 'appuntamenti': [

        ]},
        {'id':3, 'name':'Alessandro', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':4, 'name':'Andrea', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':5, 'name':'Luca', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':6, 'name':'Ludovica', 'presente': true, 'appuntamenti': [
          
        ]},
        {'id':7, 'name':'Diana', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':8, 'name':'Matteo', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':9, 'name':'Alessio', 'presente': true, 'appuntamenti': [
          
        ]},
        {'id':10, 'name':'Valerio', 'presente': true, 'appuntamenti': [
          
        ]},
        {'id':11, 'name':'Anna', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':12, 'name':'Beatrice', 'presente': false, 'appuntamenti': [
          
        ]},
        {'id':13, 'name':'Maria', 'presente': true, 'appuntamenti': [
          
        ]},
        {'id':14, 'name':'Sandy', 'presente': true, 'appuntamenti': [
          
        ]},
      ],
      'orari': [
        '8.30', '8.45', '9.00', '9.15', '9.30', '9.45',  '10.00', '10.15', '10.30', '10.45',  '11.00', '11.15', '11.30', '11.45', 
        '12.00', '12.15', '12.30', '12.45',  '13.00', '13.15', '13.30', '13.45',  '14.00', '14.15', '14.30', '14.45',  '15.00', '15.15', '15.30', '15.45', 
        '16.00', '16.15', '16.30', '16.45',  '17.00', '17.15', '17.30', '17.45',  '18.00', '18.15', '18.30', '18.45',  '19.00', '19.15', '19.30'
      ]
    };
  },
}
</script>
