import { createApp } from 'vue'
import App from './App.vue'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCashRegister } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
//import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import { faSignal } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faBars)
library.add(faCashRegister)
library.add(faCalendar)
library.add(faUsers)
//library.add(faChartBar)
library.add(faSignal)
library.add(faChartPie)
library.add(faLightbulb)
library.add(faExchangeAlt)
library.add(faStore)
library.add(faPrint)
library.add(faClipboardList)
library.add(faBirthdayCake)
library.add(faTicketAlt)
library.add(faFlag)
library.add(faShoppingCart)

//import { all } from '@awesome.me/kit-2a06983c65/icons'
/* add icons to the library */
//library.add(...all)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
